<template>
  <div>
    <!--------------------面包屑导航-------------------->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>用户列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!----------------------卡片区域---------------------->
    <el-card>
      <el-row :gutter="20"><!--栅格中每个组件的间距，20px-->
        <el-col :span="6"><!--占的比重，一共24-->
          <el-button type="primary" @click="addDialogVisible = true">添加用户</el-button>
        </el-col>
      </el-row>
      <!--------------------用户表格--------------------->
      <el-table :data="userList" stripe border><!--stripe带斑马纹的表格 border边框-->
        <el-table-column type="index" label="序号" width="60px">
          <template slot-scope="scope">
            <span>{{ scope.$index + 1 + queryInfo.pageSize * (queryInfo.currentPage - 1) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="userName" label="用户名"></el-table-column>
        <el-table-column prop="fullName" label="姓名"></el-table-column>
        <el-table-column prop="cellPhone" label="手机号"></el-table-column>
        <el-table-column prop="roleNames" label="角色"></el-table-column>
        <el-table-column prop="" label="操作" width="350px">
          <template slot-scope="scope" >
            <!--编辑用户-->
            <el-button type="primary" size="mini" @click="showEditDialog(scope.row)">编辑</el-button>
            <!--删除用户-->
            <el-button type="danger" size="mini" @click="removeUser(scope.row)">删除</el-button>
            <!--修改密码-->
            <el-button type="warning" size="mini" @click="showPasswordDialog(scope.row)">修改密码</el-button>
            <!--分配用户角色-->
            <el-tooltip effect="dark" content="分配角色" placement="top" :enterable="false">
              <el-button type="success" size="mini"  @click="showRoleDialog(scope.row)">分配角色</el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!--------------------分页组件--------------------->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                     :current-page="queryInfo.currentPage" :page-sizes="[10, 20, 30, 40, 50, 100]" :page-size="queryInfo.pageSize"
                     layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
      </el-pagination>
      <!--------------------添加用户对话框--------------------->
      <el-dialog title="添加用户" :visible.sync="addDialogVisible"
                 width="45%" @close="addDialogClosed">
        <!--内容主体信息-->
        <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px">
          <el-form-item label="用户名" prop="userName">
            <el-input v-model="addForm.userName"></el-input>
          </el-form-item>
          <el-form-item label="姓名" prop="fullName">
            <el-input v-model="addForm.fullName"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input type="password" v-model="addForm.password"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="cellPhone">
            <el-input v-model="addForm.cellPhone"></el-input>
          </el-form-item>
        </el-form>
        <!--底部区域-->
        <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addUser()" :loading="loading">确 定</el-button>
      </span>
      </el-dialog>
      <!--------------------修改用户对话框--------------------->
      <el-dialog title="修改用户" :visible.sync="editDialogVisible"
                 width="45%" @close="editDialogClosed">
        <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="100px">
          <el-form-item label="用户名" prop="userName">
            <el-input v-model="editForm.userName" ></el-input><!--加disabled禁用输入框-->
          </el-form-item>
          <el-form-item label="姓名" prop="fullName">
            <el-input v-model="editForm.fullName"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="cellPhone">
            <el-input v-model="editForm.cellPhone"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editUser()" :loading="loading">确 定</el-button>
      </span>
      </el-dialog>
      <!--------------------管理员修改用户密码--------------------->
      <el-dialog title="修改密码" :visible.sync="passwordDialog" width="45%" @close="passwordDialogClosed">
        <el-form label-width="100px" :rules="updatePwdRules" :model="updatePwdForm" ref="updatePwdFormRef">
          <el-form-item label="用户名" >
            <el-input v-model="userInfo.userName" disabled></el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="newPassword">
            <el-input type="password" v-model="updatePwdForm.newPassword"></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="checkPassword">
            <el-input type="password" v-model="updatePwdForm.checkPassword"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="passwordDialog = false">取 消</el-button>
          <el-button type="primary" @click="resetPassword()" :loading="loading">确 定</el-button>
        </span>
      </el-dialog>
      <!--------------------分配角色对话框--------------------->
      <el-dialog title="分配角色" :visible.sync="setRolesDialogVisible" width="50%" @close="setRolesDialogClosed">
        <div>
          <p>当前的用户：{{userInfo.userName}}</p>
<!--          <p>当前的角色：{{userInfo.role_name}}</p>-->
          <p>分配到新角色：
            <el-select v-model="selectedRoleId" multiple placeholder="请选择">
              <el-option
                v-for="item in rolesList"
                :key="item.id"
                :label="item.roleName"
                :value="item.id">
              </el-option>
            </el-select>
          </p>
        </div>
        <span slot="footer" class="dialog-footer">
      <el-button @click="setRolesDialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="saveRoleInfo" :loading="loading">确 定</el-button>
      </span></el-dialog>
  </el-card>
  </div>
</template>

<script>
export default {
name: "User",
  data() {
    // 校验手机号
    const checkMobile = (rule, value, cb) => {
      // 验证手机号的正则表达式
      const regMobile = /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/
      if (regMobile.test(value)) {
        return cb() // 校验通过
      } else {
        return cb(new Error('请输入合法的手机号'))
      }
    }
    return {
      loading: false,
      userList: [],//用户列表
      queryInfo: {
        currentPage: 1,//当前页码
        pageSize: 10,//每页显示多少条
      },
      totalCount: 0,//总条数
      addDialogVisible: false,//添加用户对话框
      addForm: { // 要添加用户的表单对象
        userName: '',//用户名
        fullName: '',//姓名
        password: '',//密码
        cellPhone: '',//手机号
      },
      passwordDialog: false,//修改密码对话框
      userInfo:{},//对话框中用户信息
      updatePwdForm: {
        newPassword: '',
        checkPassword: '',
      },
      setRolesDialogVisible: false,//分配权限对话框
      rolesList: [], // 角色列表
      selectedRoleId: [], // 选中要分配到新角色的id
      addFormRules: { // 表单校验规则对象
        userName: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        fullName: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {min: 6, max: 11, message: '长度在 6 到 11 个字符', trigger: 'blur'}
        ],
        cellPhone: [{ required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: checkMobile, trigger: 'blur' }
        ]
      },
      editDialogVisible: false, // 默认关闭修改用户对话框
      editForm: {}, // 编译用户表单对象
      editFormRules: { // 编辑用户表单校验规则对象
        userName: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        fullName: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        cellPhone: [{ required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: checkMobile, trigger: 'blur' }
        ]
      },
      updatePwdRules: {
        newPassword: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {min: 6, max: 11, message: '长度在 6 到 11 个字符', trigger: 'blur'}
        ],
        checkPassword: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {min: 6, max: 11, message: '长度在 6 到 11 个字符', trigger: 'blur'}
        ],
      },
    }
  },
  mounted () {
    this.getUserList()
  },
  methods: {
  //打开分配角色对话框
    async showRoleDialog(row) {
      this.userInfo = row
      this.setRolesDialogVisible = true
      const { data: res } = await this.$http.post('/role/list')
      if (res.code !== 200) {
        this.$message.error(res.message)
      } else {
        this.rolesList = res.result
        this.selectedRoleId = row.roleIds
      }
    },
    // 提交分配角色
    async saveRoleInfo () {
      if (!this.selectedRoleId) {
        return this.$message.error('请选择要分配的角色！')
      } else {
        this.loading = true;
        let params = {
          id: this.userInfo.id,
          roleIds: this.selectedRoleId.join(",")
        }
        const { data: res } = await this.$http.post('/user/assignRole', params)
        this.loading = false;
        if (res.code !== 200) {
          return this.$message.error(res.message)
        } else {
          this.$message.success(res.message)
          await this.getUserList()
          this.setRolesDialogVisible = false
        }
      }
    },
    // 分配角色对话框关闭事件
    setRolesDialogClosed () {
      this.selectedRoleId = ''
      this.userInfo = ''
      this.loading = false
    },
    //关闭重置密码对话框
    passwordDialogClosed() {
      this.userInfo = {}
      this.loading = false
      this.updatePwdForm.newPassword = ''
      this.updatePwdForm.checkPassword = ''
      this.$refs.updatePwdFormRef.resetFields()
    },
    //重置密码
    async resetPassword() {
      this.$refs.updatePwdFormRef.validate(async valid => {
        if (valid) {
          if(this.updatePwdForm.checkPassword === this.updatePwdForm.newPassword) {
            this.loading = true;
            let param = {
              userId: this.userInfo.id,
              password: this.updatePwdForm.checkPassword
            }
            const { data: res } = await this.$http.put('/user/resetPassword', param)
            this.loading = false;
            if (res.code !== 200) {
              this.$message.error(res.message)
            } else {
              this.passwordDialog = false
              await this.getUserList()
              this.$message.success(res.message)
            }
          } else {
            this.$message.error('两次输入密码不一致！')
          }
        } else {
          //this.$message.error('数据校验不通过!')
        }
      })
    },
    //打开重置密码对话框
    showPasswordDialog(row) {
      this.passwordDialog = true
      this.userInfo = row
    },
    //用户列表
    async getUserList() {
      const { data: res } = await this.$http.post('user/listPage', this.queryInfo)
      if (res.code !== 200) {
        this.$message.error(res.message)
      } else {
        let list = res.result.items
        for(let i=0; i< list.length; i++) {
          let roles = list[i].roles;
          let roleNames = [];
          let roleIds = [];

          for(let j=0; j< roles.length; j++) {
            roleNames.push(roles[j].roleName);
            roleIds.push(roles[j].id);
          }
          list[i].roleNames = roleNames.join(',');
          list[i].roleIds = roleIds;
        }
        this.userList = list;
        this.totalCount = res.result.totalCount
      }
    },
    // 监听每页显示多少条的改变
    handleSizeChange (newSize) {
      this.queryInfo.pageSize = newSize
      this.getUserList()
    },
    // 监听选择第了几页
    handleCurrentChange (newPage) {
      this.queryInfo.currentPage = newPage
      this.getUserList()
    },
    // 关闭添加用户对话框的时候重置表单操作
    addDialogClosed () {
      this.loading = false;
      this.$refs.addFormRef.resetFields()
    },
    //添加用户
    addUser() {
      this.$refs.addFormRef.validate(async valid => {
        if (valid) {
          this.loading = true;
          const { data: res } = await this.$http.post('/user/add', this.addForm)
          this.loading = false;
          if (res.code !== 200) {
            this.$message.error(res.message)
          } else {
            this.addDialogVisible = false
            await this.getUserList()
            this.$message.success(res.message)
          }
        } else {
          //this.$message.error('数据校验不通过!')
        }
      })
    },
    //编辑用户
    showEditDialog(row) {
      this.editForm = row
      this.editDialogVisible = true
    },
    // 关闭编辑用户对话框的时候重置表单操作
    editDialogClosed () {
      this.editDialogVisible = false
      this.loading = false;
      this.editForm = {};
    },
    // 修改用户信息并提交
    editUser () {
      this.$refs.editFormRef.validate(async valid => {
        if (valid) {
          this.loading = true;
          const { data: res } = await this.$http.put('/user/edit', {
            id: this.editForm.id,
            userName: this.editForm.userName,
            fullName: this.editForm.fullName,
            cellPhone: this.editForm.cellPhone,
          })
          this.loading = false;
          if (res.code !== 200) {
            this.$message.error(res.message)
          } else {
            this.editDialogVisible = false // 关闭对话框
            await this.getUserList() // 刷新用户列表
            this.$message.success(res.message)
          }
        } else {
          //this.$message.error('数据校验不通过!')
        }
      })
    },
    //删除用户
    removeUser(rows) {
      this.$confirm('此操作将永久删除该条数据', '确认要删除吗？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
        center: true,
        showClose: false,
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            let {data: res} = await this.$http({
              url: '/user/del',
              params: {id: rows.id},
              headers:{
                'Content-Type':"application/x-www-form-urlencoded",
              },
              method:'delete'
            });
            if (res.code === 200) {
              done();
              instance.confirmButtonLoading = false;
              this.$message.success('操作成功！')
              await this.getUserList()
            } else {
              this.$message.error(res.message)
              instance.confirmButtonLoading = false;
              done();
            }
          } else {
            instance.confirmButtonLoading = false;
            done();
          }
        }
      }).catch(() => {
        this.$message.info('已取消');
      });
    }
  },
}
</script>

<style scoped>
  .el-pagination {
    margin-top: 15px;
  }
</style>
