<template>
  <div>
    <!--------------------面包屑导航-------------------->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>模块管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!----------------------卡片区域---------------------->
    <el-card>
      <el-row :gutter="20"><!--栅格中每个组件的间距，20px-->
        <el-col :span="6"><!--占的比重，一共24-->
          <el-button type="primary" @click="showAddDialog()">添加模块</el-button>
        </el-col>
      </el-row>
      <el-table :data="tableData" header-cell-class-name="table-head" row-key="id" :tree-props="{children: 'childModule'}">
        <el-table-column prop="moduleName" label="模块名称"></el-table-column>
        <el-table-column prop="url" label="url"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <div v-if="scope.row.parentId !== 0">
              <el-button type="primary" size="mini"  @click.native="editItems(scope.row)">编辑</el-button>
<!--              <el-button type="danger" size="mini" @click.native="removeItems(scope.row)">删除</el-button>-->
            </div>
            <div v-else>
              <el-button type="primary" size="mini" @click.native="editItems(scope.row)">编辑</el-button>
<!--              <el-button type="danger" size="mini" @click.native="removeItems(scope.row)">删除</el-button>-->
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!--------------------修改模块对话框--------------------->
      <el-dialog title="修改模块" :visible.sync="editDialogVisible"
                 width="45%" @close="editDialogClosed">
        <el-form label-width="100px">
          <el-form-item label="模块名称">
            <el-input v-model="moduleInfo.moduleName"></el-input><!--加disabled禁用输入框-->
          </el-form-item>
          <el-form-item label="url" v-if="moduleInfo.parentId !== 0">
            <el-input v-model="moduleInfo.url"></el-input><!--加disabled禁用输入框-->
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editModule()" :loading="loading">确 定</el-button>
      </span>
      </el-dialog>
      <!--------------------添加用户对话框--------------------->
      <el-dialog title="添加模块" :visible.sync="addDialogVisible"
                 width="45%" @close="addDialogClosed">
        <!--内容主体信息-->
        <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px">
          <el-form-item label="url">
            <el-input v-model="addForm.url" placeholder="请输入,格式必须以“ / ”开头"></el-input>
          </el-form-item>
          <el-form-item label="模块名称" prop="moduleName">
            <el-input v-model="addForm.moduleName"></el-input>
          </el-form-item>
          <el-form-item label="上级目录">
            <el-select v-model="addForm.parentId" clearable placeholder="请选择">
              <el-option v-for="(item, index) in tableData" :key="index" :label="item.moduleName" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="角色">
            <el-select v-model="addForm.roleIds" multiple placeholder="请选择">
              <el-option v-for="item in rolesList" :key="item.id" :label="item.roleName" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <!--底部区域-->
        <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addModule()" :loading="loading">确 定</el-button>
      </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'Module',
  data() {
    return {
      loading: false,//加载效果
      tableData: [],//表格信息
      editDialogVisible: false,//修改对话框
      moduleInfo: {},
      addDialogVisible: false,//添加对话框
      addForm: { // 要添加模块的表单对象
        url: '',//url
        moduleName: '',//模块名称
        parentId: '',//上级目录
        roleIds: []//角色id
      },
      rolesList: [],//角色数组
      addFormRules: { // 表单校验规则对象
        moduleName: [
          { required: true, message: '请输入模块名称', trigger: 'blur' }
        ]
      },
    }
  },
  mounted () {
    this.getModuleList();
  },
  methods: {
    //打开添加模块对话框
    async showAddDialog() {
      this.addDialogVisible = true
      const { data: res } = await this.$http.post('/role/list')
      if (res.code !== 200) {
        this.$message.error(res.message)
      } else {
        this.rolesList = res.result
      }
    },
    //添加模块
    addModule() {
      this.$refs.addFormRef.validate(async valid => {
        if (valid) {
          if(this.addForm.parentId !== '' && this.addForm.url === '') {
            this.$message.error('请输入url!')
          } else {
            this.loading = true;
            let param = {
              url: this.addForm.url,
              moduleName: this.addForm.moduleName,
              parentId: this.addForm.parentId === '' ? 0 : this.addForm.parentId,
              roleIds: this.addForm.roleIds.join(',')
            }
            const { data: res } = await this.$http.post('/module/add', param)
            this.loading = false;
            if (res.code !== 200) {
              this.$message.error(res.message)
            } else {
              this.addDialogVisible = false
              await this.getModuleList()
              this.$message.success(res.message)
            }
          }
          }else {
          //this.$message.error('数据校验不通过!')
        }
      })
    },
    // 关闭添加模块对话框的时候重置表单操作
    addDialogClosed () {
      this.loading = false;
      this.$refs.addFormRef.resetFields()
      this.addForm.roleIds = []
      this.addForm.url = ''
      this.addForm.moduleName = ''
      this.addForm.parentId = ''
    },
    //查询列表
    async getModuleList() {
      const { data: res } = await this.$http.get('/module/list')
      if (res.code !== 200) {
        this.$message.error(res.message)
      } else {
        this.tableData = res.result
      }
    },
    //编辑
    editItems(rows) {
      this.moduleInfo = rows
      this.editDialogVisible = true
    },
    // 关闭编辑用户对话框的时候重置表单操作
    editDialogClosed () {
      this.loading = false;
      this.moduleInfo = {}
    },
    //提交编辑
    async editModule() {
      this.loading = true;
      const { data: res } = await this.$http.put('/module/edit', {
        id: this.moduleInfo.id,
        moduleName: this.moduleInfo.moduleName,
        url: this.moduleInfo.url
      })
      this.loading = false;
      if (res.code !== 200) {
        this.$message.error(res.message)
      } else {
        this.editDialogVisible = false // 关闭对话框
        await this.getModuleList() // 刷新用户列表
        this.$message.success(res.message)
      }
    },
    //删除
    removeItems(rows) {
      this.$confirm('此操作将永久删除该条数据', '确认要删除吗？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
        center: true,
        showClose: false,
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            let {data: res} = await this.$http({
              url: '/module/del',
              params: {id: rows.id},
              headers:{
                'Content-Type':"application/x-www-form-urlencoded",
              },
              method:'delete'
            });
            if (res.code === 200) {
              done();
              instance.confirmButtonLoading = false;
              this.$message.success(res.message)
              await this.getModuleList()
            } else {
              this.$message.error(res.message)
              instance.confirmButtonLoading = false;
              done();
            }
          } else {
            instance.confirmButtonLoading = false;
            done();
          }
        }
      }).catch(() => {
        this.$message.info('已取消');
      });
    }
  }
}
</script>

<style scoped>

</style>
