<template>
  <div>
    <!--------------------面包屑导航-------------------->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>角色列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!----------------------卡片区域---------------------->
    <el-card>
      <el-row :gutter="20"><!--栅格中每个组件的间距，20px-->
        <el-col :span="6"><!--占的比重，一共24-->
          <el-button type="primary" @click="addDialogVisible = true">添加角色</el-button>
        </el-col>
      </el-row>
      <!--------------------用户表格--------------------->
      <el-table :data="roleList" stripe border><!--stripe带斑马纹的表格 border边框-->
        <el-table-column type="index" label="序号" width="60px">
          <template slot-scope="scope">
            <span>{{ scope.$index + 1 + queryInfo.pageSize * (queryInfo.currentPage - 1) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="roleName" label="角色名称"></el-table-column>
        <el-table-column prop="createTime" label="添加时间"></el-table-column>
        <el-table-column prop="" label="操作">
          <template slot-scope="scope">
            <!--编辑用户-->
            <el-button type="primary" size="mini" @click="showEditDialog(scope.row)">编辑</el-button>
            <!--删除用户-->
            <el-button type="danger" size="mini" @click="removeRole(scope.row)">删除</el-button>
            <!--分配模块-->
            <el-tooltip effect="dark" content="分配模块" placement="top" :enterable="false">
              <el-button type="warning" size="mini" @click="showSetModuleDialog(scope.row)">分配模块</el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!--------------------分页组件--------------------->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                     :current-page="queryInfo.currentPage" :page-sizes="[10, 20, 30, 40, 50, 100]" :page-size="queryInfo.pageSize"
                     layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
      </el-pagination>
      <!--------------------添加角色对话框--------------------->
      <el-dialog :title="isEdit ? '编辑角色' : '添加角色'"  :visible.sync="addDialogVisible"
                 width="45%" @close="addDialogClosed">
        <!--内容主体信息-->
        <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px">
          <el-form-item label="角色名" prop="roleName">
            <el-input v-model="addForm.roleName"></el-input>
          </el-form-item>
        </el-form>
        <!--底部区域-->
        <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addRole()" :loading="loading">确 定</el-button>
      </span>
      </el-dialog>
    </el-card>
    <!-------------------分配权限对话框区域------------------------->
    <el-dialog title="分配模块" :visible.sync="setRightDialogVisible" width="45%" @close="setModuleDialogClosed">
      <!--树形控件-->
      <!--show-checkbox加复选框，node-key指定id, default-expand-all展开所有节点,:default-checked-keys="defKeys"勾选指定的节点-->
      <el-tree :data="moduleList" :props="treeProps" show-checkbox node-key="id" default-expand-all
               :default-checked-keys="defKeys" ref="treeRef"></el-tree>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setModuleDialogClosed">取 消</el-button>
        <el-button type="primary" @click="setModule()" :loading="loading">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
name: "Role",
  data() {
    return {
      isEdit: false,
      loading: false,
      roleList: [],
      queryInfo: {
        currentPage: 1,//当前页码
        pageSize: 10,//每页显示多少条
      },
      totalCount: 0,//总条数
      addDialogVisible: false,//添加对话框
      id: '',//打开编辑角色的id
      setRightDialogVisible: false,//模块对话框
      // 权限列表
      moduleList: [],
      // 树形结构控件对应的名称，children子节点命名，label节点名称
      treeProps: {
        children: 'childModule',
        label: 'moduleName'
      },
      // 默认选中的节点id值数组
      defKeys: [],
      addForm: { // 要添加角色的表单对象
        roleName: '',//角色名
      },
      roleInfo: {},//当前角色信息
      addFormRules: { // 表单校验规则对象
        roleName: [
          { required: true, message: '请输入角色名', trigger: 'blur' }
        ]
      },
    }
  },
  mounted () {
    this.getRoleList();
    this.getModules();
  },
  methods: {
    //模块树
    async getModules() {
      const { data: res } = await this.$http.get('/module/list')
      if (res.code !== 200) {
        this.$message.error(res.message)
      } else {
        this.moduleList = res.result
      }
    },
    //打开分配模块对话框
    async showSetModuleDialog(rows) {
      this.roleInfo = rows
      let modules = rows.modules;
      let keys = []
      for(let i=0; i<modules.length; i++) {
        let child = modules[i].childModule;
        if(child !== null) {
          for(let j=0; j<child.length; j++) {
            keys.push(child[j].id);
          }
        }else {
          keys.push(modules[i].id);
        }
      }
      this.defKeys = keys
      this.setRightDialogVisible = true
    },
    //关闭分配模块对话框
    setModuleDialogClosed() {
      this.setRightDialogVisible = false
      this.$refs.treeRef.setCheckedKeys([])//清空勾选
      this.defKeys = []
    },
    //提交角色分配模块
    async setModule() {
      this.loading = true;
      const keys = [
        ...this.$refs.treeRef.getCheckedKeys(),
        ...this.$refs.treeRef.getHalfCheckedKeys()
      ]
      const idStr = keys.join(',')
      let param = {
        id: this.roleInfo.id,
        moduleIds: idStr
      }
      const { data: res } = await this.$http.post('/role/assignModule', param)
      this.loading = false;
      if (res.code !== 200) {
        this.$message.error(res.message)
      } else {
        this.$message.success(res.message)
        this.setRightDialogVisible = false
        await this.getRoleList();
      }
    },
    //打开编辑对话框
    showEditDialog(row) {
      this.isEdit = true
      this.addForm = row
      this.addDialogVisible = true
    },
    // 关闭添加用户对话框的时候重置表单操作
    addDialogClosed () {
      this.loading = false;
      this.addForm = {};
      this.isEdit = false
    },
    //添加角色
    addRole() {
      this.$refs.addFormRef.validate(async valid => {
        if (valid) {
          this.loading = true;
          let res = null;
          let param = {
            roleName: this.addForm.roleName
          }
          if (this.isEdit) {
            param.id = this.addForm.id;
            res = await this.$http.put('/role/edit', param)
          } else {
            res = await this.$http.post('/role/add', param)
          }
          let result = res.data;
          this.loading = false;
          if (result.code !== 200) {
            this.$message.error(result.message)
          } else {
            this.addDialogVisible = false
            await this.getRoleList()
            this.$message.success(result.message)
          }
        } else {
          //this.$message.error('数据校验不通过!')
        }
      })
    },
    // 监听每页显示多少条的改变
    handleSizeChange (newSize) {
      this.queryInfo.pageSize = newSize
      this.getRoleList()
    },
    // 监听选择第了几页
    handleCurrentChange (newPage) {
      this.queryInfo.currentPage = newPage
      this.getRoleList()
    },
    //角色列表
    async getRoleList() {
      const { data: res } = await this.$http.post('/role/listPage', this.queryInfo)
      if (res.code !== 200) {
        this.$message.error(res.message)
      } else {
        this.roleList = res.result.items
        this.totalCount = res.result.totalCount
      }
    },
    //删除角色
    removeRole(rows) {
      this.$confirm('此操作将永久删除该条数据', '确认要删除吗？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
        center: true,
        showClose: false,
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            let {data: res} = await this.$http({
              url: '/role/del',
              params: {id: rows.id},
              headers:{
                'Content-Type':"application/x-www-form-urlencoded",
              },
              method:'delete'
            });
            if (res.code === 200) {
              done();
              instance.confirmButtonLoading = false;
              this.$message.success(res.message)
              await this.getRoleList()
            } else {
              this.$message.error(res.message)
              instance.confirmButtonLoading = false;
              done();
            }
          } else {
            instance.confirmButtonLoading = false;
            done();
          }
        }
      }).catch(() => {
        this.$message.info('已取消');
      });
    }
  }
}
</script>

<style scoped>
.el-pagination {
  margin-top: 15px;
}
</style>
