<template>
  <div class="container">
    <!--------------------面包屑导航-------------------->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
    </el-breadcrumb>
    <!----------------------卡片区域---------------------->
    <div class="content">
      <!--内容主体信息-->
      <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px">
        <el-form-item label="密码" prop="newPwd">
          <el-input type="password" v-model="addForm.newPwd"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="checkPwd">
          <el-input type="password" v-model="addForm.checkPwd"></el-input>
        </el-form-item>
        <el-button type="primary" class="reset-btn" @click="resetPwd()" :loading="loading">确  定</el-button>
      </el-form>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Password',
  data() {
    return {
      loading: false,
      addForm: {
        newPwd: '',
        checkPwd: ''
      },
      addFormRules: { // 表单校验规则对象
        newPwd: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {min: 6, max: 11, message: '长度在 6 到 11 个字符', trigger: 'blur'}
        ],
        checkPwd: [
          { required: true, message: '请输入确认密码', trigger: 'blur' },
          {min: 6, max: 11, message: '长度在 6 到 11 个字符', trigger: 'blur'}
        ],
      },
    }
  },
  methods: {
    resetPwd() {
      this.$refs.addFormRef.validate(async valid => {
        if (valid) {
          if (this.addForm.newPwd === this.addForm.checkPwd) {
            this.loading = true;
            let param = {
              password: this.addForm.checkPwd
            }
            const { data: res } = await this.$http.put('/user/editPassword', param)
            this.loading = false;
            if (res.code !== 200) {
              this.$message.error(res.message)
            } else {
              this.$message.success(res.message)
            }
          } else {
            this.$message.error('两次输入密码不一致！')
          }
        }else {
          //this.$message.error('数据校验不通过!')
        }
      });
    }
  }
}
</script>

<style scoped>
  .container {
    height: 100%;
  }
  .content {
    height: 93%;
    background-color: #fff;
    box-sizing: border-box;
    padding: 100px 0 0 0;
  }
  .el-form {
    width: 50%;
    margin: 0 auto;
  }
  .reset-btn {
    width: 25%;
    margin-left: 50%;
    transform: translateX(-50%);
  }
</style>
